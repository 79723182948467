body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
  background-color: #eef2f6;
  width: calc(100% - 260px);
  min-height: calc(100vh - 88px);
  flex-grow: 1;
  padding: 20px;
  margin-top: 72px;
  margin-right: 20px;
  border-radius: 12px;
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-left: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#root {
  height: 100%;
}
.sc-paper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.sc-login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  min-height: 300px;
  max-height: 700px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 450px;
  background-color: #ffffff;
}

.sc-google-login {
  width: 100%;
  justify-content: center;
}

.sc-paper .sc-login-box {
  box-shadow: none;
}

.sc-google-login div {
  background-color: transparent !important;
}

/***********************************************************/
/*               Syncfusion Scheduler                      */
/***********************************************************/
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-icon-btn.e-edit {
  display: none;
}
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment-wrapper .e-appointment {
  border-radius: 5px;
}
.e-schedule .e-vertical-view td.e-work-cells,
.e-schedule .e-vertical-view .e-time-cells-wrap table td.e-time-slots {
  height: 40px;
  /* width: 50px; */
}
/* .e-schedule .e-vertical-view td.e-work-cells {
  width: 200px;
} */
/* .e-schedule .e-vertical-view .e-header-cells,
.e-schedule .e-vertical-view .e-all-day-cells {
  width: 100px;
} */

/***********************************************************/
/*              Google Place Auto-Complete                 */
/***********************************************************/
.pac-container {
  z-index: 9999;
}

/***********************************************************/
/*                  Syncfusion Date Picker                 */
/***********************************************************/
.e-schedule .e-schedule-toolbar .e-views.e-active-view .e-icons,
.e-schedule .e-schedule-toolbar .e-views.e-active-view .e-tbar-btn-text,
.e-schedule .e-vertical-view .e-header-row .e-header-cells.e-current-day,
.e-schedule .e-timeline-view .e-date-header-wrap table .e-header-row td.e-current-day,
.e-schedule .e-timeline-month-view .e-date-header-wrap table .e-header-row td.e-current-day {
  color: #a7727d;
}

.e-footer-container .e-btn.e-flat.e-primary,
.e-footer-container .e-css.e-btn.e-flat.e-primary,
.e-footer-container .e-btn.e-flat.e-primary:hover,
.e-footer-container .e-css.e-btn.e-flat.e-primary:hover {
  color: #a7727d;
}
.e-footer-container .e-btn.e-flat.e-primary:hover,
.e-footer-container .e-css.e-btn.e-flat.e-primary:hover {
  opacity: 0.8;
  color: #a7727d;
}
.e-calendar div.e-content td.e-selected.e-focused-date span.e-day {
  background-color: #a7727d;
}
.e-calendar div.e-content td.e-today.e-selected span.e-day,
.e-calendar div.e-content td.e-today.e-selected:hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today:hover span.e-day {
  background-color: #a7727d;
  border-color: #a7727d;
}
.e-calendar div.e-content td.e-selected span.e-day {
  background-color: #a7727d;
}
.e-calendar div.e-content td.e-today:hover span.e-day {
  border-color: #a7727d;
  color: #a7727d;
}
.e-calendar div.e-content.e-year td.e-selected:hover span.e-day,
.e-calendar div.e-content.e-decade td.e-selected:hover span.e-day {
  opacity: 0.8;
  background-color: #a7727d;
}
/***********************************************************/
/*                    Upload file input                    */
/***********************************************************/
input[type='file']#logo-upload {
  display: none;
}
.custom-logo-upload {
  /* border: 1px solid #ccc; */
  color: #495579;
  font-weight: 500;
  border-radius: 4px;
  padding: 6px 40px;
  font-size: 0.875rem;
  line-height: 1.75;
  cursor: pointer;
}
.custom-logo-upload:hover {
  /* background-color: rgba(73, 85, 121, 0.04); */
  text-decoration: underline;
}
/* input::file-selector-button {
  background-color: transparent;
  font-weight: bold;
  padding: 0.5em;
  border: none;
  border-radius: 3px;
}
input::file-selector-button:hover {
  cursor: pointer;
} */

/* .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input */

/* .e-input-group.e-control-wrapper:not(.e-float-icon-left), */

/***********************************************************/
/*              Syncfusion Date Range Picker               */
/***********************************************************/
.s-date-range-picker .e-input-group.e-control-wrapper.e-date-range-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px;
  height: 40px;
}
.s-date-range-picker .e-date-range-wrapper .e-input-group-icon.e-icons.e-active {
  color: rgba(0, 0, 0, 0.54);
}
.s-date-range-picker .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  font-size: 21px;
  margin-left: 10px;
  margin-right: 6px;
}
.s-date-range-picker .e-input-group.e-control-wrapper.e-date-range-wrapper input.e-daterangepicker {
  margin: 0 10px;
  font-size: 14px;
}
td.e-start-date.e-selected.e-range-hover span.e-day,
td.e-end-date.e-selected.e-range-hover span.e-day {
  background-color: #a7727d;
}
.e-daterangepicker.e-popup .e-calendar td.e-start-date.e-selected.e-range-hover span.e-day,
.e-daterangepicker.e-popup .e-calendar td.e-end-date.e-selected.e-range-hover span.e-day {
  background-color: #a7727d;
}
.e-daterangepicker.e-popup .e-calendar td.e-start-date.e-range-hover.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar td.e-start-date.e-selected.e-range-hover.e-today span.e-day {
  border-color: #a7727d;
  background-color: #a7727d;
}
div.e-calendar .e-content td.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span.e-day {
  color: #a7727d;
  border-color: #a7727d;
}
.e-daterangepicker.e-popup .e-presets li.e-list-item.e-active {
  color: #a7727d;
}
.e-btn.e-flat.e-primary,
button.e-css.e-btn.e-flat.e-primary {
  color: #a7727d;
}
.e-calendar div.e-content td.e-selected:hover span.e-day {
  background-color: #a7727d;
}

/*****************************************/
/*                Grid                   */
/*****************************************/
/* #s-grid-reset-customization */
#s-grid-clear-filters {
  border: 1px solid #495579;
}

#s-grid-clear-filters > span {
  color: #495579;
  font-weight: bold;
  border-radius: 3px;
  padding: 4px 6px;
}

.s-new-entity {
  margin-bottom: -40px;
  z-index: 900;
  width: fit-content;
  block-size: fit-content;
}
